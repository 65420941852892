<template>
    <div class="home">
        <div class="container">
            <div class="data-list full-width with-footer">
                <div class="data-item" :key="index" v-for="(item, index) in datalist">
                    <div class="item-title">{{item.title}}</div>
                    <div class="item-desc">
                        <!-- <div class="item-desc-1">
                            学分：<strong class="strong">{{item.score}}</strong>
                        </div> -->
                        <div class="item-desc-2">{{item.add_time}}</div>
                        <div class="item-desc-1">
                            学分：<strong class="strong">{{item.score}}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { getRecord} from '../api/task.js';
    import { checkLogin } from '../utils/common.js';
    export default{
        data() {
            return {
                datalist :[],
            }
        },
        activated() {
            checkLogin().then(()=> {
                this.getList(); 
            })
        },
        methods:{
            getList(){
               getRecord().then((res)=> {
                   console.log(res.data);
                   this.datalist = res.data;
               })
            },
        },
        
    }
</script>

<style scoped>
    @import url('../assets/css/common.css');
.home{
    height: 100vh;
    background-color: #fff;
}
.data-list{
    background-color: #fff;
}
.data-item{
    text-align: left;
    padding: 10px 15px;
    border-bottom: 1px solid #f2f2f2;
}
.data-item .item-title{
    font-size: 15px;
    color: #333;
    /* font-weight: bold; */
    line-height: 1.8;
}
.data-item .item-desc{
    display: flex;
    font-size: 14px;
    color: #666;
    justify-content: space-between;
}
.data-item .strong{
    color: #ff6a26;
    font-size: 15px;
    font-weight: bold;
}
</style>